var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "home container"
  }, [_c('div', {
    staticClass: "card max480 tac"
  }, [_c('h1', [_vm._v("Consense Web-App")]), _c('div', {
    staticClass: "spacer"
  }), !_vm.isFb ? _c('div', [_c('router-link', {
    staticClass: "bold",
    attrs: {
      "to": "/login"
    }
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("Zur Anmeldung")])])], 1) : _vm.isFb ? _c('div', [_c('p', [_vm._v("Links im Menü auswählen!")]), _c('p'), _c('div', {
    staticClass: "tar link bold color-red",
    on: {
      "click": function click($event) {
        return _vm.$store.dispatch('_logOut');
      }
    }
  }, [_vm._v("Abmelden")]), _c('p'), _c('p', [_c('router-link', {
    staticClass: "link bold",
    attrs: {
      "to": "/changelogs"
    }
  }, [_vm._v("Was ist neu in Version " + _vm._s(_vm.$env.version))])], 1)]) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
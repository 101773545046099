import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
var self = null;
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  name: "Home",
  components: {},
  beforeCreate: function beforeCreate() {
    self = this;
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread({}, mapState(["mobile"])), {}, {
    isAndroind: function isAndroind() {
      return ((self.mobile || {}).os || {}).name == "Android";
    }
  }),
  methods: {}
};